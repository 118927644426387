import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Home.module.css";
import ColorButtons from "../../components/ColorButtons";
import RowButtons from "../../components/RowButtons";
import ColumnButtons from "../../components/ColumnButtons";
import IconButtons from "../../components/IconButtons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  FaApple,
  FaHeart,
  FaStar,
  FaSun,
  FaLeaf,
  FaCloud,
  FaMoon,
  FaBolt,
  FaSmile,
} from "react-icons/fa"; // Import the required icons

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function generateGridAndColors() {
  const grid = [];
  let colors = [
    "red",
    "blue",
    "green",
    "purple",
    "orange",
    "pink",
    "yellow",
    "magenta",
    "gray",
  ];

  colors = shuffleArray(colors);

  const iconIndexes = Array.from({ length: 18 }, () =>
    Math.floor(Math.random() * 9)
  );

  for (let i = 0; i < 18; i++) {
    const row = [];
    const numberCounts = Array(9).fill(0);

    for (let j = 0; j < 18; j++) {
      const number = ((i * 3 + Math.floor(i / 3) + j) % 9) + 1;
      if (numberCounts[number - 1] < 2) {
        row.push(number);
        numberCounts[number - 1]++;
      } else {
        let newNumber = number + 1;
        if (newNumber > 9) {
          newNumber = 1;
        }
        row.push(newNumber);
      }
    }
    grid.push(row);
  }
  return { grid, colors, iconIndexes };
}

function Home() {
  const numRows = 18;
  const numColumns = 18;
  const {
    grid: generatedGrid,
    colors: generatedColors,
    iconIndexes,
  } = generateGridAndColors();
  const [selectedCell, setSelectedCell] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [grid, setGrid] = useState(generatedGrid);
  const [colors, setColors] = useState(generatedColors);
  const availableIcons = [
    FaApple,
    FaHeart,
    FaStar,
    FaSun,
    FaLeaf,
    FaCloud,
    FaMoon,
    FaBolt,
    FaSmile,
  ];

  const colorMapping = {
    red: "#CF2456",
    blue: "#0389CD",
    green: "#17A26E",
    purple: "#7B3295",
    orange: "#DF5451",
    pink: "#CB799E",
    yellow: "#DCC246",
    brown: "#762728",
    gray: "#838E9F",
  };

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [hoveredColumnIndex, setHoveredColumnIndex] = useState(null);

  useEffect(() => {
    const { grid: newGrid, colors: newColors } = generateGridAndColors();
    setGrid(newGrid);
    setColors(newColors);
  }, []);

  const handleColorButtonClick = (color) => {
    const newGrid = grid.map((row) =>
      row.map((cell) => (colorMapping[colors[cell - 1]] === color ? 0 : cell))
    );
    setGrid(newGrid);
  };

  const handleRowButtonClick = (rowIndex) => {
    const newGrid = grid.map((row, rowIndex2) =>
      rowIndex === rowIndex2 ? row.map(() => 0) : row
    );
    setGrid(newGrid);
  };

  const handleColumnButtonClick = (colIndex) => {
    const newGrid = grid.map((row) =>
      row.map((cell, columnIndex) => (colIndex === columnIndex ? 0 : cell))
    );
    setGrid(newGrid);
  };

  const handleIconButtonClick = (Icon) => {
    const iconIndex = availableIcons.indexOf(Icon);
    const newGrid = grid.map((row) =>
      row.map((cell) => (cell - 1 === iconIndex ? 0 : cell))
    );
    setGrid(newGrid);
  };

  const handleCellClick = (rowIndex, columnIndex, iconName, color) => {
    if (!selectedCell) {
      setSelectedCell({
        row: rowIndex + 1,
        column: columnIndex + 1,
        icon: iconName,
        color,
      });
      setShowModal(true);
    }
  };

  const handleCellHover = (rowIndex, columnIndex) => {
    setHoveredRowIndex(rowIndex);
    setHoveredColumnIndex(columnIndex);
  };

  function selectRandomCell() {
    const randomRowIndex = Math.floor(Math.random() * numRows);
    const randomColumnIndex = Math.floor(Math.random() * numColumns);
    const selectedCell = {
      row: randomRowIndex + 1,
      column: randomColumnIndex + 1,
      icon: availableIcons[grid[randomRowIndex][randomColumnIndex] - 1],
      color: colors[grid[randomRowIndex][randomColumnIndex] - 1],
    };
    return selectedCell;
  }
  const [randomlySelectedCell, setRandomlySelectedCell] = useState(null);
  const [isSelectedCellConfirmed, setIsSelectedCellConfirmed] = useState(false);
  const [isRandomlySelectedCellConfirmed, setIsRandomlySelectedCellConfirmed] =
    useState(false);

  const handleModalClose = () => {
    setSelectedCell(null); // Reset selectedCell to null
    setShowModal(false);
  };

  const handleConfirmSelection = () => {
    const randomCell = selectRandomCell();
    setRandomlySelectedCell(randomCell);

    if (selectedCell) {
      setSelectedCell({
        ...selectedCell,
        icon: availableIcons[
          grid[selectedCell.row - 1][selectedCell.column - 1] - 1
        ],
      });
    }

    setIsSelectedCellConfirmed(true); // Set selected cell confirmation status to true
    setIsRandomlySelectedCellConfirmed(true); // Set randomly selected cell confirmation status to true
    setShowModal(false);
  };

  return (
    <div>
      <div className="row m-0">
        <div className={`col ${styles.introSection}`}>
          <h2 className={styles.introSectionTitle}>Telepathy</h2>
        </div>
      </div>
      <div className={styles.container}>
        <div className="row m-0">
          <div className={`col ${styles.userOptions}`}>
            <div className={styles.selectionsWrapper}>
              {/* Display selected cell details */}
              {isSelectedCellConfirmed && (
                <div
                  className={styles.selectedCellDetails}
                  style={{ backgroundColor: selectedCell.color }}
                >
                  <h3>Your Cell</h3>
                  <p>Row: {selectedCell.row}</p>
                  <p>Column: {selectedCell.column}</p>
                  {selectedCell.icon && (
                    <p>
                      Icon: <selectedCell.icon className={styles.icon} />
                    </p>
                  )}
                  <p>Color: {selectedCell.color}</p>
                </div>
              )}
              {/* Display randomly selected cell details */}
              {isRandomlySelectedCellConfirmed && (
                <div
                  className={styles.randomlySelectedCellDetails}
                  style={{ backgroundColor: randomlySelectedCell.color }}
                >
                  <h3>Their Cell</h3>
                  <p>Row: {randomlySelectedCell.row}</p>
                  <p>Column: {randomlySelectedCell.column}</p>
                  {randomlySelectedCell.icon && (
                    <p>
                      Icon:{" "}
                      <randomlySelectedCell.icon className={styles.icon} />
                    </p>
                  )}
                  <p>Color: {randomlySelectedCell.color}</p>
                </div>
              )}
            </div>
            {/* Display the row, column, icon, and color buttons only if a cell has been selected */}
            {isSelectedCellConfirmed ? (
              <div>
                <RowButtons
                  numRows={numRows}
                  handleClick={handleRowButtonClick}
                />
                <ColumnButtons
                  numColumns={numColumns}
                  handleClick={handleColumnButtonClick}
                />
                <IconButtons
                  icons={availableIcons}
                  handleClick={handleIconButtonClick}
                />
                <ColorButtons
                  colors={colorMapping}
                  handleClick={handleColorButtonClick}
                />
              </div>
            ) : (
              <div className={styles.chooseCellDialog}>
                <h2>Please choose a cell</h2>
              </div>
            )}
          </div>
          <div className="col p-0">
            {/* Row with column numbers */}
            <div className="row m-0 justify-content-end">
              <div className={`col ${styles.gridCol}`}>
                <div className={styles.gridRow}>
                  <div
                    className={`${styles.gridCell} ${styles.columnNumberCell}`}
                  ></div>{" "}
                  {/* Empty cell at top-left corner */}
                  {Array.from({ length: numColumns }).map((_, columnIndex) => (
                    <div
                      key={columnIndex}
                      className={`${styles.gridCell} ${styles.columnNumberCell}`}
                    >
                      {columnIndex + 1}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Grid with row and column numbers */}
            <div className="row m-0 justify-content-end">
              {grid.map((row, rowIndex) => (
                <div key={rowIndex} className={`col ${styles.gridCol}`}>
                  <div className={styles.gridRow}>
                    {/* Row number cell */}
                    <div
                      className={`${styles.gridCell} ${styles.rowNumberCell}`}
                    >
                      {rowIndex + 1}
                    </div>
                    {row.map((cell, columnIndex) => {
                      const Icon = availableIcons[cell - 1];
                      const color = colors[cell - 1];
                      const isHoveredRowFirstCell =
                        rowIndex === hoveredRowIndex;
                      const isHoveredColumnFirstCell =
                        columnIndex === hoveredColumnIndex;
                      return (
                        <div
                          key={columnIndex}
                          className={`${styles.gridCell} ${
                            (isHoveredRowFirstCell ||
                              isHoveredColumnFirstCell) &&
                            styles.hoverEffect
                          }`}
                          style={{ backgroundColor: color }}
                          onMouseEnter={() =>
                            handleCellHover(rowIndex, columnIndex)
                          }
                          onMouseLeave={() => handleCellHover(null, null)}
                          onClick={() =>
                            handleCellClick(rowIndex, columnIndex, Icon, color)
                          }
                        >
                          {availableIcons[cell - 1] && (
                            <Icon className={styles.icon} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCell ? (
            <>
              <p>Are you sure you want to select this cell?</p>
              <p>
                Row: {selectedCell.row}, Column: {selectedCell.column}
              </p>
              {selectedCell.icon && (
                <p>
                  Icon: <selectedCell.icon className={styles.icon} />
                </p>
              )}
              <p>Color: {selectedCell.color}</p>
            </>
          ) : (
            <p>No cell selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmSelection}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Home;
