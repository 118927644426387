import React, { useState } from "react";
import styles from "./Telepathy.module.css";
import { BsLightningCharge, BsArrowThroughHeart } from "react-icons/bs";
import { PiMoonStarsLight, PiSunLight } from "react-icons/pi";
import { LiaEyeSolid } from "react-icons/lia";
import {
  IoDiamondOutline,
  IoHandRightOutline,
  IoStarOutline,
} from "react-icons/io5";
import { GiSuperMushroom } from "react-icons/gi";
const colorMap = {
  blue: "#099CDA",
  pink: "#D87DAD",
  green: "#339563",
  brown: "#975e36",
  silver: "#8998A6",
  purple: "#754494",
  red: "#D81F58",
  orange: "#D26651",
  yellow: "#DDCD52",
  transparent: "transparent",
};

function Telepathy() {
  const handleUserButtonClick = (e) => {
    const sameColorElements = document.querySelectorAll(
      `[iconcolor="${e.getAttribute("colorname")}"]`
    );
    const sameIconElements = document.querySelectorAll(
      `[iconname="${e.getAttribute("iconshape")}"]`
    );
    const sameRowElements = document.querySelectorAll(
      `[rowindex="${e.getAttribute("rowcount")}"]`
    );
    const sameColElements = document.querySelectorAll(
      `[colindex="${e.getAttribute("colcount")}"]`
    );
    if (e.classList.contains(styles.buttonHighlight)) {
      e.classList.remove(styles.buttonHighlight);
      e.classList.add(styles.disabled);
      sameRowElements.forEach((element) => {
        if (e.getAttribute("rowindex") == 0) {
        } else {
          element.classList.remove(styles.highlight);
          element.classList.add(styles.rowTransparent);
        }
      });
      sameColElements.forEach((element) => {
        if (e.getAttribute("colindex") == 0) {
        } else {
          element.classList.remove(styles.highlight);
          element.classList.add(styles.colTransparent);
        }
      });
      sameColorElements.forEach((element) => {
        if (e.getAttribute("colorname") == 0) {
        } else {
          element.classList.remove(styles.highlight);
          element.classList.add(styles.colorTransparent);
        }
      });
      sameIconElements.forEach((element) => {
        if (e.getAttribute("iconshape") == 0) {
        } else {
          element.classList.remove(styles.highlight);
          element.classList.add(styles.iconTransparent);
        }
      });
    } else if (e.classList.contains(styles.disabled)) {
      e.classList.remove(styles.disabled);
      sameRowElements.forEach((element) => {
        if (e.getAttribute("rowindex") == 0) {
        } else {
          element.classList.remove(styles.rowTransparent);
        }
      });
      sameColElements.forEach((element) => {
        if (e.getAttribute("colindex") == 0) {
        } else {
          element.classList.remove(styles.colTransparent);
        }
      });
      sameColorElements.forEach((element) => {
        if (e.getAttribute("colorname") == 0) {
        } else {
          element.classList.remove(styles.colorTransparent);
        }
      });
      sameIconElements.forEach((element) => {
        if (e.getAttribute("iconshape") == 0) {
        } else {
          element.classList.remove(styles.iconTransparent);
        }
      });
    } else {
      e.classList.add(styles.buttonHighlight);
      sameRowElements.forEach((element) => {
        if (e.getAttribute("rowindex") == 0) {
        } else {
          element.classList.add(styles.highlight);
        }
      });
      sameColElements.forEach((element) => {
        if (e.getAttribute("colindex") == 0) {
        } else {
          element.classList.add(styles.highlight);
        }
      });
      sameColorElements.forEach((element) => {
        if (e.getAttribute("colorname") == 0) {
        } else {
          element.classList.add(styles.highlight);
        }
      });
      sameIconElements.forEach((element) => {
        if (e.getAttribute("iconshape") == 0) {
        } else {
          element.classList.add(styles.highlight);
        }
      });
    }
  };

  const [cellChosen, SetCellChosen] = useState(false);
  const [confirmChoice, setConfirmChoice] = useState(false);
  const [choiceButtons, setChoiceButtons] = useState(false);
  const [userRow, setUserRow] = useState();
  const [userCol, setUserCol] = useState();
  const [userColor, setUserColor] = useState();
  const [userIcon, setUserIcon] = useState();

  const handleConfirmClick = (e) => {
    setConfirmChoice(false);
    setChoiceButtons(true);
    if (e.getAttribute("selection") == "true") {
      SetCellChosen(true);
      setConfirmChoice(false);
    } else {
      setUserRow();
      setUserCol();
      setUserColor();
      setUserIcon();
    }
  };

  const handleCellClick = (e) => {
    if (cellChosen) {
      if (e.classList.contains(styles.highlight)) {
        e.classList.remove(styles.highlight);
        e.classList.add(styles.userTransparent);
      } else if (e.classList.contains(styles.userTransparent)) {
        e.classList.remove(styles.userTransparent);
      } else {
        e.classList.add(styles.highlight);
      }
    } else {
      setConfirmChoice(true);
      setUserRow(e.getAttribute("rowindex"));
      setUserCol(e.getAttribute("colindex"));
      setUserColor(e.getAttribute("iconcolor"));
      setUserIcon(e.getAttribute("iconname"));
    }
  };

  const handleMouseOver = (rowindex, colindex) => {
    const sameRowElements = document.querySelectorAll(
      `[rowindex="${rowindex}"]`
    );
    const sameColElements = document.querySelectorAll(
      `[colindex="${colindex}"]`
    );
    sameRowElements.forEach((rowElement) => {
      if (rowindex == 0) {
      } else {
        rowElement.classList.add(styles.hoverState);
      }
    });
    sameColElements.forEach((colElement) => {
      if (colindex == 0) {
      } else {
        colElement.classList.add(styles.hoverState);
      }
    });
  };

  const handleMouseOut = (rowindex, colindex) => {
    const sameRowElements = document.querySelectorAll(
      `[rowindex="${rowindex}"]`
    );
    const sameColElements = document.querySelectorAll(
      `[colindex="${colindex}"]`
    );
    sameRowElements.forEach((rowElement) => {
      if (rowindex == 0) {
      } else {
        rowElement.classList.remove(styles.hoverState);
      }
    });
    sameColElements.forEach((colElement) => {
      if (colindex == 0) {
      } else {
        colElement.classList.remove(styles.hoverState);
      }
    });
  };

  return (
    <div className={styles.main}>
      <div className={styles.intro}>
        <h1 className={styles.title}>Telepathy</h1>
      </div>
      <div className={styles.game}>
        <div
          className={`col-12 col-xl-6 ${styles.userSelect} ${
            cellChosen ? styles.hide : styles.show
          }`}
        >
          <h1
            className={`${styles.chooseCellTitle} ${
              cellChosen ? styles.hide : styles.show
            }`}
          >
            Choose a cell from the grid
          </h1>
          <div
            className={`${styles.userChooseCell} ${
              confirmChoice ? styles.show : styles.hide
            }`}
          >
            <p className={styles.sure}>
              Are you sure you want {userRow} {userCol} {userColor} {userIcon}?
            </p>
            <div>
              <button
                selection="true"
                className={`${styles.selectButton} ${styles.yes}`}
                onClick={(e) => handleConfirmClick(e.target)}
              >
                Yes
              </button>
              <button
                selection="false"
                className={`${styles.selectButton} ${styles.no}`}
                onClick={(e) => handleConfirmClick(e.target)}
              >
                No
              </button>
            </div>
          </div>
        </div>
        <div
          className={`col-12 col-xl-6 ${styles.userOptions} ${
            cellChosen ? styles.show : styles.hide
          }`}
        >
          <h1
            className={`${styles.userCell} ${
              cellChosen ? styles.show : styles.hide
            }`}
          >
            Your Cell is {userRow} {userCol} {userColor} {userIcon}
          </h1>
          <h1 className={styles.rowTitle}>Row</h1>
          <div className={styles.rowButtons}>
            <button
              className={styles.rowButton}
              rowcount={"A"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              A
            </button>
            <button
              className={styles.rowButton}
              rowcount={"B"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              B
            </button>
            <button
              className={styles.rowButton}
              rowcount={"C"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              C
            </button>
            <button
              className={styles.rowButton}
              rowcount={"D"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              D
            </button>
            <button
              className={styles.rowButton}
              rowcount={"E"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              E
            </button>
            <button
              className={styles.rowButton}
              rowcount={"F"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              F
            </button>
            <button
              className={styles.rowButton}
              rowcount={"G"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              G
            </button>
            <button
              className={styles.rowButton}
              rowcount={"H"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              H
            </button>
            <button
              className={styles.rowButton}
              rowcount={"I"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              I
            </button>
            <button
              className={styles.rowButton}
              rowcount={"J"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              J
            </button>
            <button
              className={styles.rowButton}
              rowcount={"K"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              K
            </button>
            <button
              className={styles.rowButton}
              rowcount={"L"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              L
            </button>
            <button
              className={styles.rowButton}
              rowcount={"M"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              M
            </button>
            <button
              className={styles.rowButton}
              rowcount={"N"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              N
            </button>
            <button
              className={styles.rowButton}
              rowcount={"O"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              O
            </button>
            <button
              className={styles.rowButton}
              rowcount={"P"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              P
            </button>
            <button
              className={styles.rowButton}
              rowcount={"Q"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Q
            </button>
            <button
              className={styles.rowButton}
              rowcount={"R"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              R
            </button>
          </div>
          <h1 className={styles.colTitle}>Column</h1>
          <div className={styles.colButtons}>
            <button
              className={styles.colButton}
              colcount={"1"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              1
            </button>
            <button
              className={styles.colButton}
              colcount={"2"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              2
            </button>
            <button
              className={styles.colButton}
              colcount={"3"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              3
            </button>
            <button
              className={styles.colButton}
              colcount={"4"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              4
            </button>
            <button
              className={styles.colButton}
              colcount={"5"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              5
            </button>
            <button
              className={styles.colButton}
              colcount={"6"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              6
            </button>
            <button
              className={styles.colButton}
              colcount={"7"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              7
            </button>
            <button
              className={styles.colButton}
              colcount={"8"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              8
            </button>
            <button
              className={styles.colButton}
              colcount={"9"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              9
            </button>
            <button
              className={styles.colButton}
              colcount={"10"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              10
            </button>
            <button
              className={styles.colButton}
              colcount={"11"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              11
            </button>
            <button
              className={styles.colButton}
              colcount={"12"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              12
            </button>
            <button
              className={styles.colButton}
              colcount={"13"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              13
            </button>
            <button
              className={styles.colButton}
              colcount={"14"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              14
            </button>
            <button
              className={styles.colButton}
              colcount={"15"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              15
            </button>
            <button
              className={styles.colButton}
              colcount={"16"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              16
            </button>
            <button
              className={styles.colButton}
              colcount={"17"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              17
            </button>
            <button
              className={styles.colButton}
              colcount={"18"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              18
            </button>
          </div>
          <h1 className={styles.colorTitle}>Color</h1>
          <div className={styles.colorButtons}>
            <button
              className={styles.colorButton}
              colorname={"blue"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Blue
            </button>
            <button
              className={styles.colorButton}
              colorname={"pink"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Pink
            </button>
            <button
              className={styles.colorButton}
              colorname={"green"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Green
            </button>
            <button
              className={styles.colorButton}
              colorname={"brown"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Brown
            </button>
            <button
              className={styles.colorButton}
              colorname={"silver"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Silver
            </button>
            <button
              className={styles.colorButton}
              colorname={"purple"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Purple
            </button>
            <button
              className={styles.colorButton}
              colorname={"red"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Red
            </button>
            <button
              className={styles.colorButton}
              colorname={"orange"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Orange
            </button>
            <button
              className={styles.colorButton}
              colorname={"yellow"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              Yellow
            </button>
          </div>
          <h1 className={styles.iconTitle}>Icon</h1>
          <div className={styles.iconButtons}>
            <button
              className={styles.iconButton}
              iconshape={"lightning"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <BsLightningCharge className={styles.icon} />
            </button>
            <button
              className={styles.iconButton}
              iconshape={"mushroom"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <GiSuperMushroom className={styles.icon} />
            </button>
            <button
              className={styles.iconButton}
              iconshape={"diamond"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <IoDiamondOutline className={styles.icon} />
            </button>
            <button
              className={styles.iconButton}
              iconshape={"hand"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <IoHandRightOutline className={styles.icon} />
            </button>
            <button
              className={styles.iconButton}
              iconshape={"moon"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <PiMoonStarsLight className={styles.icon} />
            </button>
            <button
              className={styles.iconButton}
              iconshape={"eye"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <LiaEyeSolid className={styles.icon} />
            </button>
            <button
              className={styles.iconButton}
              iconshape={"star"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <IoStarOutline className={styles.icon} />
            </button>
            <button
              className={styles.iconButton}
              iconshape={"heart"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <BsArrowThroughHeart className={styles.icon} />
            </button>
            <button
              className={styles.iconButton}
              iconshape={"sun"}
              style={{ backgroundColor: colorMap.transparent }}
              onClick={(e) => handleUserButtonClick(e.target)}
            >
              <PiSunLight className={styles.icon} />
            </button>
          </div>
        </div>
        <div className={`col-12 col-xl-6 ${styles.grid}`}>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="0"
            ></div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="1"
            >
              <p>1</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="2"
            >
              <p>2</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="3"
            >
              <p>3</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="4"
            >
              <p>4</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="5"
            >
              <p>5</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="6"
            >
              <p>6</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="7"
            >
              <p>7</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="8"
            >
              <p>8</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="9"
            >
              <p>9</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="10"
            >
              <p>10</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="11"
            >
              <p>11</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="12"
            >
              <p>12</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="13"
            >
              <p>13</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="14"
            >
              <p>14</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="15"
            >
              <p>15</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="16"
            >
              <p>16</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="17"
            >
              <p>17</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="0"
              colindex="18"
            >
              <p>18</p>
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="A"
              colindex="0"
            >
              <p>A</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"lightning"}
              rowindex="A"
              colindex="1"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"mushroom"}
              rowindex="A"
              colindex="2"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"diamond"}
              rowindex="A"
              colindex="3"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"hand"}
              rowindex="A"
              colindex="4"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"moon"}
              rowindex="A"
              colindex="5"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"eye"}
              rowindex="A"
              colindex="6"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"star"}
              rowindex="A"
              colindex="7"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"heart"}
              rowindex="A"
              colindex="8"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"sun"}
              rowindex="A"
              colindex="9"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"eye"}
              rowindex="A"
              colindex="10"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"star"}
              rowindex="A"
              colindex="11"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"mushroom"}
              rowindex="A"
              colindex="12"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"moon"}
              rowindex="A"
              colindex="13"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"sun"}
              rowindex="A"
              colindex="14"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"lightning"}
              rowindex="A"
              colindex="15"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"diamond"}
              rowindex="A"
              colindex="16"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"hand"}
              rowindex="A"
              colindex="17"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"heart"}
              rowindex="A"
              colindex="18"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="B"
              colindex="0"
            >
              <p>B</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"hand"}
              rowindex="B"
              colindex="1"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"heart"}
              rowindex="B"
              colindex="2"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"eye"}
              rowindex="B"
              colindex="3"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"mushroom"}
              rowindex="B"
              colindex="4"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"lightning"}
              rowindex="B"
              colindex="5"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"sun"}
              rowindex="B"
              colindex="6"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"moon"}
              rowindex="B"
              colindex="7"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"diamond"}
              rowindex="B"
              colindex="8"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"star"}
              rowindex="B"
              colindex="9"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"hand"}
              rowindex="B"
              colindex="10"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"heart"}
              rowindex="B"
              colindex="11"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"star"}
              rowindex="B"
              colindex="12"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"diamond"}
              rowindex="B"
              colindex="13"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"mushroom"}
              rowindex="B"
              colindex="14"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"eye"}
              rowindex="B"
              colindex="15"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"lightning"}
              rowindex="B"
              colindex="16"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"sun"}
              rowindex="B"
              colindex="17"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"moon"}
              rowindex="B"
              colindex="18"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="C"
              colindex="0"
            >
              <p>C</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"heart"}
              rowindex="C"
              colindex="1"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"eye"}
              rowindex="C"
              colindex="2"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"star"}
              rowindex="C"
              colindex="3"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"diamond"}
              rowindex="C"
              colindex="4"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"mushroom"}
              rowindex="C"
              colindex="5"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"moon"}
              rowindex="C"
              colindex="6"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"hand"}
              rowindex="C"
              colindex="7"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"sun"}
              rowindex="C"
              colindex="8"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"lightning"}
              rowindex="C"
              colindex="9"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"star"}
              rowindex="C"
              colindex="10"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"lightning"}
              rowindex="C"
              colindex="11"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"diamond"}
              rowindex="C"
              colindex="12"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"hand"}
              rowindex="C"
              colindex="13"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"moon"}
              rowindex="C"
              colindex="14"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"mushroom"}
              rowindex="C"
              colindex="15"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"sun"}
              rowindex="C"
              colindex="16"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"heart"}
              rowindex="C"
              colindex="17"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"eye"}
              rowindex="C"
              colindex="18"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="D"
              colindex="0"
            >
              <p>D</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"eye"}
              rowindex="D"
              colindex="1"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"star"}
              rowindex="D"
              colindex="2"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"lightning"}
              rowindex="D"
              colindex="3"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"sun"}
              rowindex="D"
              colindex="4"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"diamond"}
              rowindex="D"
              colindex="5"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"hand"}
              rowindex="D"
              colindex="6"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"heart"}
              rowindex="D"
              colindex="7"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"moon"}
              rowindex="D"
              colindex="8"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"mushroom"}
              rowindex="D"
              colindex="9"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"diamond"}
              rowindex="D"
              colindex="10"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"sun"}
              rowindex="D"
              colindex="11"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"hand"}
              rowindex="D"
              colindex="12"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"star"}
              rowindex="D"
              colindex="13"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"eye"}
              rowindex="D"
              colindex="14"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"moon"}
              rowindex="D"
              colindex="15"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"heart"}
              rowindex="D"
              colindex="16"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"lightning"}
              rowindex="D"
              colindex="17"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"mushroom"}
              rowindex="D"
              colindex="18"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="E"
              colindex="0"
            >
              <p>E</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"star"}
              rowindex="E"
              colindex="1"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"lightning"}
              rowindex="E"
              colindex="2"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"mushroom"}
              rowindex="E"
              colindex="3"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"moon"}
              rowindex="E"
              colindex="4"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"sun"}
              rowindex="E"
              colindex="5"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"heart"}
              rowindex="E"
              colindex="6"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"eye"}
              rowindex="E"
              colindex="7"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"hand"}
              rowindex="E"
              colindex="8"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"diamond"}
              rowindex="E"
              colindex="9"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"sun"}
              rowindex="E"
              colindex="10"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"moon"}
              rowindex="E"
              colindex="11"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"heart"}
              rowindex="E"
              colindex="12"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"lightning"}
              rowindex="E"
              colindex="13"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"star"}
              rowindex="E"
              colindex="14"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"hand"}
              rowindex="E"
              colindex="15"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"eye"}
              rowindex="E"
              colindex="16"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"mushroom"}
              rowindex="E"
              colindex="17"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"diamond"}
              rowindex="E"
              colindex="18"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="F"
              colindex="0"
            >
              <p>F</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"mushroom"}
              rowindex="F"
              colindex="1"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"diamond"}
              rowindex="F"
              colindex="2"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"sun"}
              rowindex="F"
              colindex="3"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"heart"}
              rowindex="F"
              colindex="4"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"hand"}
              rowindex="F"
              colindex="5"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"star"}
              rowindex="F"
              colindex="6"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"lightning"}
              rowindex="F"
              colindex="7"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"eye"}
              rowindex="F"
              colindex="8"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"moon"}
              rowindex="F"
              colindex="9"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"lightning"}
              rowindex="F"
              colindex="10"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"mushroom"}
              rowindex="F"
              colindex="11"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"sun"}
              rowindex="F"
              colindex="12"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"heart"}
              rowindex="F"
              colindex="13"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"hand"}
              rowindex="F"
              colindex="14"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"diamond"}
              rowindex="F"
              colindex="15"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"moon"}
              rowindex="F"
              colindex="16"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"eye"}
              rowindex="F"
              colindex="17"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"star"}
              rowindex="F"
              colindex="18"
            >
              <IoStarOutline className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="G"
              colindex="0"
            >
              <p>G</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"moon"}
              rowindex="G"
              colindex="1"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"hand"}
              rowindex="G"
              colindex="2"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"heart"}
              rowindex="G"
              colindex="3"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"lightning"}
              rowindex="G"
              colindex="4"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"star"}
              rowindex="G"
              colindex="5"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"diamond"}
              rowindex="G"
              colindex="6"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"sun"}
              rowindex="G"
              colindex="7"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"mushroom"}
              rowindex="G"
              colindex="8"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"eye"}
              rowindex="G"
              colindex="9"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"moon"}
              rowindex="G"
              colindex="10"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"hand"}
              rowindex="G"
              colindex="11"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"eye"}
              rowindex="G"
              colindex="12"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"mushroom"}
              rowindex="G"
              colindex="13"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"lightning"}
              rowindex="G"
              colindex="14"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"heart"}
              rowindex="G"
              colindex="15"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"star"}
              rowindex="G"
              colindex="16"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"diamond"}
              rowindex="G"
              colindex="17"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"sun"}
              rowindex="G"
              colindex="18"
            >
              <PiSunLight className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="H"
              colindex="0"
            >
              <p>H</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"sun"}
              rowindex="H"
              colindex="1"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"moon"}
              rowindex="H"
              colindex="2"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"hand"}
              rowindex="H"
              colindex="3"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"star"}
              rowindex="H"
              colindex="4"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"eye"}
              rowindex="H"
              colindex="5"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"mushroom"}
              rowindex="H"
              colindex="6"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"diamond"}
              rowindex="H"
              colindex="7"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"lightning"}
              rowindex="H"
              colindex="8"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"heart"}
              rowindex="H"
              colindex="9"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"mushroom"}
              rowindex="H"
              colindex="10"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"diamond"}
              rowindex="H"
              colindex="11"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"moon"}
              rowindex="H"
              colindex="12"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"eye"}
              rowindex="H"
              colindex="13"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"heart"}
              rowindex="H"
              colindex="14"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"sun"}
              rowindex="H"
              colindex="15"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"hand"}
              rowindex="H"
              colindex="16"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"star"}
              rowindex="H"
              colindex="17"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"lightning"}
              rowindex="H"
              colindex="18"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="I"
              colindex="0"
            >
              <p>I</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"diamond"}
              rowindex="I"
              colindex="1"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"sun"}
              rowindex="I"
              colindex="2"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"moon"}
              rowindex="I"
              colindex="3"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"eye"}
              rowindex="I"
              colindex="4"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"heart"}
              rowindex="I"
              colindex="5"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"lightning"}
              rowindex="I"
              colindex="6"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"mushroom"}
              rowindex="I"
              colindex="7"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"star"}
              rowindex="I"
              colindex="8"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"hand"}
              rowindex="I"
              colindex="9"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"heart"}
              rowindex="I"
              colindex="10"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"eye"}
              rowindex="I"
              colindex="11"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"lightning"}
              rowindex="I"
              colindex="12"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"sun"}
              rowindex="I"
              colindex="13"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"diamond"}
              rowindex="I"
              colindex="14"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"star"}
              rowindex="I"
              colindex="15"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"mushroom"}
              rowindex="I"
              colindex="16"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"moon"}
              rowindex="I"
              colindex="17"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"hand"}
              rowindex="I"
              colindex="18"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="J"
              colindex="0"
            >
              <p>J</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"moon"}
              rowindex="J"
              colindex="1"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"hand"}
              rowindex="J"
              colindex="2"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"sun"}
              rowindex="J"
              colindex="3"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"mushroom"}
              rowindex="J"
              colindex="4"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"lightning"}
              rowindex="J"
              colindex="5"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"star"}
              rowindex="J"
              colindex="6"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"diamond"}
              rowindex="J"
              colindex="7"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"eye"}
              rowindex="J"
              colindex="8"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"heart"}
              rowindex="J"
              colindex="9"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"sun"}
              rowindex="J"
              colindex="10"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"hand"}
              rowindex="J"
              colindex="11"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"heart"}
              rowindex="J"
              colindex="12"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"mushroom"}
              rowindex="J"
              colindex="13"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"moon"}
              rowindex="J"
              colindex="14"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"diamond"}
              rowindex="J"
              colindex="15"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"eye"}
              rowindex="J"
              colindex="16"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"star"}
              rowindex="J"
              colindex="17"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"lightning"}
              rowindex="J"
              colindex="18"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="K"
              colindex="0"
            >
              <p>K</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"hand"}
              rowindex="K"
              colindex="1"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"heart"}
              rowindex="K"
              colindex="2"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"moon"}
              rowindex="K"
              colindex="3"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"diamond"}
              rowindex="K"
              colindex="4"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"mushroom"}
              rowindex="K"
              colindex="5"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"lightning"}
              rowindex="K"
              colindex="6"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"sun"}
              rowindex="K"
              colindex="7"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"star"}
              rowindex="K"
              colindex="8"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"eye"}
              rowindex="K"
              colindex="9"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"hand"}
              rowindex="K"
              colindex="10"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"eye"}
              rowindex="K"
              colindex="11"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"star"}
              rowindex="K"
              colindex="12"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"sun"}
              rowindex="K"
              colindex="13"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"heart"}
              rowindex="K"
              colindex="14"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"moon"}
              rowindex="K"
              colindex="15"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"lightning"}
              rowindex="K"
              colindex="16"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"mushroom"}
              rowindex="K"
              colindex="17"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"diamond"}
              rowindex="K"
              colindex="18"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="L"
              colindex="0"
            >
              <p>L</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"mushroom"}
              rowindex="L"
              colindex="1"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"diamond"}
              rowindex="L"
              colindex="2"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"lightning"}
              rowindex="L"
              colindex="3"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"eye"}
              rowindex="L"
              colindex="4"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"heart"}
              rowindex="L"
              colindex="5"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"hand"}
              rowindex="L"
              colindex="6"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"star"}
              rowindex="L"
              colindex="7"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"moon"}
              rowindex="L"
              colindex="8"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"sun"}
              rowindex="L"
              colindex="9"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"lightning"}
              rowindex="L"
              colindex="10"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"diamond"}
              rowindex="L"
              colindex="11"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"sun"}
              rowindex="L"
              colindex="12"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"eye"}
              rowindex="L"
              colindex="13"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"mushroom"}
              rowindex="L"
              colindex="14"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"star"}
              rowindex="L"
              colindex="15"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"moon"}
              rowindex="L"
              colindex="16"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"hand"}
              rowindex="L"
              colindex="17"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"heart"}
              rowindex="L"
              colindex="18"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="M"
              colindex="0"
            >
              <p>M</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"heart"}
              rowindex="M"
              colindex="1"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"eye"}
              rowindex="M"
              colindex="2"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"hand"}
              rowindex="M"
              colindex="3"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"sun"}
              rowindex="M"
              colindex="4"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"diamond"}
              rowindex="M"
              colindex="5"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"mushroom"}
              rowindex="M"
              colindex="6"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"moon"}
              rowindex="M"
              colindex="7"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"lightning"}
              rowindex="M"
              colindex="8"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"star"}
              rowindex="M"
              colindex="9"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"mushroom"}
              rowindex="M"
              colindex="10"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"sun"}
              rowindex="M"
              colindex="11"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"moon"}
              rowindex="M"
              colindex="12"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"star"}
              rowindex="M"
              colindex="13"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"diamond"}
              rowindex="M"
              colindex="14"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"lightning"}
              rowindex="M"
              colindex="15"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"hand"}
              rowindex="M"
              colindex="16"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"heart"}
              rowindex="M"
              colindex="17"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"eye"}
              rowindex="M"
              colindex="18"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="N"
              colindex="0"
            >
              <p>N</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"lightning"}
              rowindex="N"
              colindex="1"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"mushroom"}
              rowindex="N"
              colindex="2"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"star"}
              rowindex="N"
              colindex="3"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"heart"}
              rowindex="N"
              colindex="4"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"hand"}
              rowindex="N"
              colindex="5"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"moon"}
              rowindex="N"
              colindex="6"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"eye"}
              rowindex="N"
              colindex="7"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"sun"}
              rowindex="N"
              colindex="8"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"diamond"}
              rowindex="N"
              colindex="9"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"moon"}
              rowindex="N"
              colindex="10"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"heart"}
              rowindex="N"
              colindex="11"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"eye"}
              rowindex="N"
              colindex="12"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"diamond"}
              rowindex="N"
              colindex="13"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"hand"}
              rowindex="N"
              colindex="14"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"sun"}
              rowindex="N"
              colindex="15"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"star"}
              rowindex="N"
              colindex="16"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"lightning"}
              rowindex="N"
              colindex="17"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"mushroom"}
              rowindex="N"
              colindex="18"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="O"
              colindex="0"
            >
              <p>O</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"eye"}
              rowindex="O"
              colindex="1"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"star"}
              rowindex="O"
              colindex="2"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"heart"}
              rowindex="O"
              colindex="3"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"moon"}
              rowindex="O"
              colindex="4"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"sun"}
              rowindex="O"
              colindex="5"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"diamond"}
              rowindex="O"
              colindex="6"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"hand"}
              rowindex="O"
              colindex="7"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"mushroom"}
              rowindex="O"
              colindex="8"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"lightning"}
              rowindex="O"
              colindex="9"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"diamond"}
              rowindex="O"
              colindex="10"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"moon"}
              rowindex="O"
              colindex="11"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"hand"}
              rowindex="O"
              colindex="12"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"lightning"}
              rowindex="O"
              colindex="13"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"sun"}
              rowindex="O"
              colindex="14"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"mushroom"}
              rowindex="O"
              colindex="15"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"heart"}
              rowindex="O"
              colindex="16"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"eye"}
              rowindex="O"
              colindex="17"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"star"}
              rowindex="O"
              colindex="18"
            >
              <IoStarOutline className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="P"
              colindex="0"
            >
              <p>P</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"star"}
              rowindex="P"
              colindex="1"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"lightning"}
              rowindex="P"
              colindex="2"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"eye"}
              rowindex="P"
              colindex="3"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"hand"}
              rowindex="P"
              colindex="4"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"moon"}
              rowindex="P"
              colindex="5"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"sun"}
              rowindex="P"
              colindex="6"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"heart"}
              rowindex="P"
              colindex="7"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"diamond"}
              rowindex="P"
              colindex="8"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"mushroom"}
              rowindex="P"
              colindex="9"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"star"}
              rowindex="P"
              colindex="10"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"mushroom"}
              rowindex="P"
              colindex="11"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"diamond"}
              rowindex="P"
              colindex="12"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"heart"}
              rowindex="P"
              colindex="13"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"lightning"}
              rowindex="P"
              colindex="14"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"eye"}
              rowindex="P"
              colindex="15"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"sun"}
              rowindex="P"
              colindex="16"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"moon"}
              rowindex="P"
              colindex="17"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"hand"}
              rowindex="P"
              colindex="18"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="Q"
              colindex="0"
            >
              <p>Q</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"sun"}
              rowindex="Q"
              colindex="1"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"moon"}
              rowindex="Q"
              colindex="2"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"diamond"}
              rowindex="Q"
              colindex="3"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"lightning"}
              rowindex="Q"
              colindex="4"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"star"}
              rowindex="Q"
              colindex="5"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"eye"}
              rowindex="Q"
              colindex="6"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"mushroom"}
              rowindex="Q"
              colindex="7"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"heart"}
              rowindex="Q"
              colindex="8"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"hand"}
              rowindex="Q"
              colindex="9"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"eye"}
              rowindex="Q"
              colindex="10"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"lightning"}
              rowindex="Q"
              colindex="11"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"mushroom"}
              rowindex="Q"
              colindex="12"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"hand"}
              rowindex="Q"
              colindex="13"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"star"}
              rowindex="Q"
              colindex="14"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"heart"}
              rowindex="Q"
              colindex="15"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"diamond"}
              rowindex="Q"
              colindex="16"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"sun"}
              rowindex="Q"
              colindex="17"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"moon"}
              rowindex="Q"
              colindex="18"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
          </div>
          <div className={`row ${styles.gridRow}`}>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              iconcolor={"transparent"}
              style={{ backgroundColor: colorMap.transparent }}
              iconname={"transparent"}
              rowindex="R"
              colindex="0"
            >
              <p>R</p>
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"diamond"}
              rowindex="R"
              colindex="1"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"sun"}
              rowindex="R"
              colindex="2"
            >
              <PiSunLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"mushroom"}
              rowindex="R"
              colindex="3"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"star"}
              rowindex="R"
              colindex="4"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"eye"}
              rowindex="R"
              colindex="5"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"heart"}
              rowindex="R"
              colindex="6"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"lightning"}
              rowindex="R"
              colindex="7"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"hand"}
              rowindex="R"
              colindex="8"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"moon"}
              rowindex="R"
              colindex="9"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"green"}
              style={{ backgroundColor: colorMap.green }}
              iconname={"heart"}
              rowindex="R"
              colindex="10"
            >
              <BsArrowThroughHeart className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"yellow"}
              style={{ backgroundColor: colorMap.yellow }}
              iconname={"star"}
              rowindex="R"
              colindex="11"
            >
              <IoStarOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"brown"}
              style={{ backgroundColor: colorMap.brown }}
              iconname={"lightning"}
              rowindex="R"
              colindex="12"
            >
              <BsLightningCharge className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"blue"}
              style={{ backgroundColor: colorMap.blue }}
              iconname={"moon"}
              rowindex="R"
              colindex="13"
            >
              <PiMoonStarsLight className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"silver"}
              style={{ backgroundColor: colorMap.silver }}
              iconname={"eye"}
              rowindex="R"
              colindex="14"
            >
              <LiaEyeSolid className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"pink"}
              style={{ backgroundColor: colorMap.pink }}
              iconname={"hand"}
              rowindex="R"
              colindex="15"
            >
              <IoHandRightOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"orange"}
              style={{ backgroundColor: colorMap.orange }}
              iconname={"mushroom"}
              rowindex="R"
              colindex="16"
            >
              <GiSuperMushroom className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"purple"}
              style={{ backgroundColor: colorMap.purple }}
              iconname={"diamond"}
              rowindex="R"
              colindex="17"
            >
              <IoDiamondOutline className={styles.icon} />
            </div>
            <div
              className={`col ${styles.gridColumn}`}
              onMouseOver={(e) =>
                handleMouseOver(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onMouseOut={(e) =>
                handleMouseOut(
                  e.target.getAttribute("rowindex"),
                  e.target.getAttribute("colindex")
                )
              }
              onClick={(e) => handleCellClick(e.target)}
              iconcolor={"red"}
              style={{ backgroundColor: colorMap.red }}
              iconname={"sun"}
              rowindex="R"
              colindex="18"
            >
              <PiSunLight className={styles.icon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Telepathy;
